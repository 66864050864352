import React, { useState } from 'react'
import Calendly from './Calendly'

function BlocVertTexteCTA(props) {
  const [popupCalendly, setPopupCalendly] = useState(false)

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  const {
    headline,
    lienTitreCta
  } = props.data

  return (
    <section className="section-bloc-vert-cta">
      <div className="container">
        <div className="row">
          <div className="columns col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12">
            <div className="box-item">
              <h2 className="large">{headline}</h2>
            </div>
          </div>
          <div className="columns col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12">
            <div className="box-item">
              <button onClick={(e) => setPopupCalendly(true)} className="btn btn-secondary btn-blanc"><span></span>{lienTitreCta}</button>
            </div>
          </div>
        </div>
      </div>
      <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
    </section>
  )
}

export default BlocVertTexteCTA
