import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import Faq from '../components/Faq'
import BlocVertTexteCTA from '../components/BlocVertTexteCTA'
import Calendly from '../components/Calendly'

function Integration(props) {
  const [language, setLanguage] = useState('1')
  const [nodeLocale, setNodeLocale] = useState('')
  const [filter, setFilter] = useState('All')
  const [overlay, setOverlay] = useState(true)
  const [condition, setCondition] = useState(true)
  const [titrePopup, setTitrePopup] = useState('')
  const [courteDescriptionPopup, setCourteDescriptionPopup] = useState('')
  const [longueDescriptionPopup, setLongueDescriptionPopup] = useState('')
  const [logoPopup, setLogoPopup] = useState('')
  const [popupCalendly, setPopupCalendly] = useState(false)

  const onClosePopup = e => {
    e.preventDefault()
    setCondition(!condition)
    setOverlay(!overlay)
  }

  const getLanguage = (data) => {
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        setLanguage(0)
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language')) === '1') {
        setLanguage(1)
        setNodeLocale('fr')
      }
    }
  }, [nodeLocale])

  const handleChange = e => {
    let value = e.target.value
    setFilter(value)
  }

  const keyPress = () => {}

  const data = props.data
  const {
    headline,
    excerpt,
    blocCtaBtns,
    blocJauneTexte,
    blocCtaVert,
    faq
  } = data.allContentfulIntegration.nodes[`${language}`]
  const partners = data.allContentfulIntegrationPartners.nodes
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.allContentfulIntegration.nodes[`${language}`]
  const partnersCategories = data.allContentfulCategoriesFeatures.edges.sort((a, b) => {
      const titreA = a.node.titre.toLowerCase()
      const titreB = b.node.titre.toLowerCase()
      if (titreA < titreB) return -1
      if (titreA > titreB) return 1
      return 0
  })

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language} getLanguage={getLanguage}/>
      <Layout data={data}>
        <SEO pagePath="/features" postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-integration">
          <main className="integration">

            <div
              role="row"
              tabIndex={0}
              onKeyPress={keyPress}
              onClick={(e) => {setOverlay(!overlay); setCondition(!condition)}}
              className={overlay ? 'overlay' : 'overlay open'}
            />

            <section className="section-1">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-7 col-md-12">
                    <div className="box-item">
                      <h1 className="headline">{headline}</h1>
                      <h2 className="description">{excerpt}</h2>
                      <div className="box-btns">
                        <button className="btn btn-primary" onClick={(e) => setPopupCalendly(true)}><span>{blocCtaBtns.ctaLienGauche}</span></button>
                        <Link to={blocCtaBtns.ctaUrlLienDroite} title=""><button className="btn btn-primary btn-blanc"><span>{blocCtaBtns.ctaLienDroite}</span></button></Link>
                      </div>
                    </div>
                  </div>
                  <div className="columns col-lg-5 col-md-12">
                    <div className="box-bitmaps">
                      <div className="box-logo-round-1"><img src="../images/logo-flitdesk-small.svg" alt=""/></div>
                      <div className="box-logo-round-2"><img src="../images/logo-google.png" alt=""/></div>
                      <div className="box-logo-round-3"><img src="../images/logo-stripe.png" alt=""/></div>
                      <div className="box-logo-round-4"><img src="../images/logo-cisco.svg" alt=""/></div>
                      <div className="box-logo-round-5"><img src="../images/logo-kisi.png" alt=""/></div>
                      <div className="box-logo-round-6"><img src="../images/logo-microsoft-windows.png" alt=""/></div>
                      <div className="box-logo-round-7"><img src="../images/logo-hello-sign.png" alt=""/></div>
                      <div className="box-logo-round-8"><img src="../images/logo-slack.png" alt=""/></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <ul className="partners filters">
                  <li className={filter === 'All' ? 'checked' : '' }>
                    <button
                      tabIndex={0}
                      onKeyPress={keyPress}
                      onClick={(e) => setFilter('All')}>{language === '0' ? 'All' : 'Tous'}
                    </button>
                  </li>

                  {partnersCategories
                    .filter(({ node }) => node.node_locale === nodeLocale)
                    .map(({ node }) =>
                      <li className={filter === node.filter ? 'checked' : '' } key={node.filter}>
                        <button
                          tabIndex={0}
                          onKeyPress={keyPress}
                          onClick={(e) => setFilter(node.filter)}>{node.titre}
                        </button>
                      </li>
                    )}

                </ul>

                <select className="partners filters" onBlur={(e) => handleChange(e)}>
                  <option className={filter === 'All' ? 'checked' : '' } value="All">{language === '0' ? 'All' : 'Tous'}</option>

                    {partnersCategories
                      .filter(({ node }) => node.node_locale === nodeLocale)
                      .map(({ node }) => <option className={filter === node.filter ? 'checked' : '' } value={node.filter} key={node.filter}>{node.titre}</option>
                    )}

                </select>
              </div>
            </section>

            <section className="section-3">
              <div className="container container-masonery grid">

              {partners
                .filter((item) => item.node_locale === nodeLocale)
                .map((item) =>
                  <div
                    role="row"
                    tabIndex={0}
                    onKeyPress={keyPress}
                    onClick={(e) => {
                      setOverlay(!overlay);
                      setCondition(!condition);
                      setTitrePopup(item.titre);
                      setCourteDescriptionPopup(item.courteDescription);
                      setLongueDescriptionPopup(item.longueDescription.childMarkdownRemark.html);
                      setLogoPopup(item.logo.file.url)
                    }}
                    className={`row selector-item ${filter === item.categorie ? 'checked' : '' } ${filter === 'All' ? 'checked' : '' }`}
                    key={item.id}
                    data-filter="calendar-apps">
                    <div className="columns col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9">
                      <div className="box-description">
                        <h3>{item.titre}</h3>
                        <p>{item.courteDescription}</p>
                        <p className="lien">{language === 0 ? 'See details' : 'Voir les détails' }</p>
                      </div>
                    </div>
                    <div className="columns col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                      <div className="box-icon">
                        <img src={item.logo.file.url} alt={item.logo.description}/>
                      </div>
                    </div>
                  </div>
              )}

              </div>

              <p className="more">{language === 0 ? <span>And many more...</span> : <span>Et bien d'autres</span> }</p>

              <div className={condition ? "popup-integration" : "popup-integration open"}>
                <div
                  onClick={onClosePopup}
                  className="close"
                  title=""
                  role="row"
                  tabIndex={0}
                  onKeyPress={keyPress}
                ></div>
                <div className="box-content">
                  <div className="head-partner">
                    <div className="row">
                      <div className="columns col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                        <div className="box-icon">
                          <img src={logoPopup} className={titrePopup === 'Stripe' ? 'full' : ''} alt=""/>
                        </div>
                      </div>
                      <div className="columns col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8">
                        <div className="box-description">
                          <div>
                            <h3 className="headline">{titrePopup}</h3>
                            <p>{courteDescriptionPopup}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="long-description" dangerouslySetInnerHTML={{ __html: longueDescriptionPopup }}/>
                </div>
              </div>
            </section>

            <section className="section-4">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6">
                    <div className="box-item">
                      <h2 className="extra-large">{blocJauneTexte.headline}</h2>
                      <p>{blocJauneTexte.texte.texte}</p>
                      <Link to={blocJauneTexte.lienInterne} className="btn btn-secondary btn-blanc tundora"><span></span>{blocJauneTexte.lienTitreCta}</Link>
                    </div>
                  </div>
                  <div className="columns col-lg-6">
                    <ul>
                      <li className="google"><div className="icon"><img src="../images/logo-google.png" alt="Run the office better|Faites mieux tourner le bureau"/></div></li>
                      <li><div className="icon"><img src="../images/logo-stripe.png" alt="Run the office better|Faites mieux tourner le bureau"/></div></li>
                      <li className="slack"><div className="icon"><img src="../images/logo-slack.png" alt="Run the office better|Faites mieux tourner le bureau"/></div></li>
                      <li><div className="icon"></div></li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <BlocVertTexteCTA data={blocCtaVert}/>
            <Faq data={faq}/>

            <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
          </main>
        </div>
      </Layout>
    <Footer getLanguage={getLanguage}/>
  </div>
  )
}

export const query = graphql`
  query IntegrationQuery {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulIntegrationPartners {
      nodes {
        node_locale
        courteDescription
        logo {
          description
          file {
            url
          }
        }
        longueDescription {
          childMarkdownRemark {
            html
          }
        }
        titre
        categorie
        id
      }
    }
    allContentfulCategoriesFeatures {
      edges {
        node {
          id
          titre
          filter
          node_locale
          createdAt
        }
      }
    }
    allContentfulIntegration {
      nodes {
        id
        node_locale
        titre
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        headline
        excerpt
        blocCtaBtns {
          ctaLienDroite
          ctaLienGauche
          ctaTexteDroite {
            ctaTexteDroite
          }
          ctaTexteGauche {
            ctaTexteGauche
          }
          ctaUrlLienDroite
          ctaUrlLienGauche
        }
        blocJauneTexte {
          lienInterne
          lienTitreCta
          headline
          texte {
            texte
          }
        }
        blocCtaVert {
          lienTitreCta
          lienInterne
          headline
        }
        faq {
          question1
          question2
          question3
          question4
          question5
          question6
          question7
          question8
          rponse1 {
            rponse1
          }
          rponse2 {
            rponse2
          }
          rponse3 {
            rponse3
          }
          rponse4 {
            rponse4
          }
          rponse5 {
            rponse5
          }
          rponse6 {
            rponse6
          }
          rponse7 {
            rponse7
          }
          rponse8 {
            rponse8
          }
        }
      }
    }
  }
`

export default Integration;
